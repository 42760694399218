h2 {
  color: rgba(0, 0, 0, 0.54) !important;
  font-weight: bold !important;
  text-transform: uppercase;
  font: 700 "Roboto", sans-serif !important;
}

.ant-form-item {
  margin-bottom: 0em !important;
}

/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
  .ant-drawer-content-wrapper {
    width: 100% !important;
  }
}

/* Medium devices (landscape tablets, 768px and up) */
@media only screen and (min-width: 768px) {
  .ant-drawer-content-wrapper {
    width: 80% !important;
  }
  .biggerModal {
    width: 80% !important;
  }
}

/* Large devices (laptops/desktops, 992px and up) */
@media only screen and (min-width: 992px) {
  .ant-drawer-content-wrapper {
    width: 60% !important;
  }
  .biggerModal {
    width: 70% !important;
  }
}

/* Extra large devices (large laptops and desktops, 1200px and up) */
@media only screen and (min-width: 1200px) {
  .ant-drawer-content-wrapper {
    width: 50% !important;
  }
}
.modal-footer,
.drawer-footer {
  position: absolute;
  width: 100%;
  padding: 10px 16px;
  text-align: right;
  left: 0;
  bottom: 0;
  background: white;
  border-radius: 0 0 4px 4px;
}

.drawer-footer {
  border-top: 1px solid #e8e8e8;
  bottom: 0;
  z-index: 1;
}

.ant-form-item-control {
  margin-bottom: 0.5em;
}
.greenRow {
  background-color: lightgreen;
}
.redRow {
  background-color: lightcoral;
}
.ant-layout-sider-zero-width-trigger {
  background: linear-gradient(
    141deg,
    #9fb8ad 0%,
    #1fc8db 51%,
    #2cb5e8 75%
  ) !important;
  top: 85vh !important;
  z-index: 3 !important;
}

.custom-filter-dropdown {
  padding: 8px;
  border-radius: 6px;
  background: #fff;
  box-shadow: 0 1px 6px rgba(0, 0, 0, 0.2);
}

.custom-filter-dropdown input {
  width: 130px;
  margin-right: 8px;
}

.custom-filter-dropdown button {
  margin-right: 8px;
}

.highlight {
  color: #f50;
}

.ant-table-header {
  overflow-y: hidden !important ;
}
