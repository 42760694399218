@import '../../../assets/styles/theme.less';

.ant-btn-group
  .ant-btn-primary
  + .ui-btn.ant-btn:not(.ant-btn-primary):not([disabled]) {
  border-left-color: initial;
}

.ui-btn:not(:disabled) {
  color: #ffffff;

  &.ant-btn-danger {
    border-color: @error-color;
    background-color: @error-color;
  }

  &.ant-btn-success {
    background-color: @success-color;
    border-color: @success-color;
  }

  &.ant-btn-info {
    background-color: @info-color;
    border-color: @info-color;
  }

  &.ant-btn-warning {
    background-color: @warning-color;
    border-color: @warning-color;
  }

  &:hover {
    color: #ffffff;

    &.ant-btn-primary {
      background-color: @primary-5;
      border-color: @primary-5;
    }

    &.ant-btn-danger {
      background-color: @error-5;
      border-color: @error-5;
    }

    &.ant-btn-success {
      background-color: @success-5;
      border-color: @success-5;
    }

    &.ant-btn-info {
      background-color: @info-5;
      border-color: @info-5;
    }

    &.ant-btn-warning {
      background-color: @warning-5;
      border-color: @warning-5;
    }
  }

  &.ui-btn-outline {
    background-color: #ffffff;

    &.ant-btn-primary {
      color: @primary-color;
    }

    &.ant-btn-danger {
      color: @error-color;
    }

    &.ant-btn-success {
      color: @success-color;
    }

    &.ant-btn-info {
      color: @info-color;
    }

    &.ant-btn-warning {
      color: @warning-color;
    }
  }

  &.ui-btn-outline:hover {
    background-color: #ffffff;

    &.ant-btn-primary {
      color: @primary-5;
      border-color: @primary-5;
    }

    &.ant-btn-danger {
      color: @error-5;
      border-color: @error-5;
    }

    &.ant-btn-success {
      color: @success-5;
      border-color: @success-5;
    }

    &.ant-btn-info {
      color: @info-5;
      border-color: @info-5;
    }

    &.ant-btn-warning {
      color: @warning-5;
      border-color: @warning-5;
    }
  }

  &.ui-btn-outline-none,
  &.ui-btn-outline-none:hover {
    border-color: transparent !important;
  }
}

.ant-dropdown-menu-item .ui-btn {
  background-color: transparent !important;
  box-shadow: none;
  padding: 0;
}
