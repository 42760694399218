@media screen {
  .print-only {
    display: none !important;
  }
}

@media print {
  .print-only {
    display: initial;
  }

  .ant-layout-sider {
    display: none;
  }
  .ant-layout-content {
    min-height: 100vh;
    height: auto !important;
  }

  .no-page-break {
    page-break-inside: avoid !important;
    position: relative;
  }
}
