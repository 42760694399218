@import "../../../assets/styles/theme.less";

@grid-padding: 11px;
@grid-print-padding: 6px;

.ag-theme-material .ag-ltr {
  font-family: @font-family;
  font-size: @font-size-base;

  .ag-header-cell-label {
    font-family: inherit;
    font-size: inherit;
  }

  .ag-row-selected {
    border-color: @blue-1;
    background-color: @blue-1;
  }

  .ag-cell-focus {
    border-color: @blue-4;
  }

  .header-grid,
  .ag-header-cell-label,
  .ag-header-group-cell,
  .ag-row-group.ag-full-width-row,
  .ag-cell.ag-cell-not-inline-editing {
    line-height: 34px;
    // text-transform: uppercase;
  }

  .ag-header-cell,
  .ag-header-group-cell {
    padding: 0 0 0 @grid-padding;

    &.right {
      padding: 0;
      .ag-header-cell-label,
      .ag-header-group-cell-label {
        justify-content: flex-end;
        padding: 0 @grid-padding 0 0;
      }
    }

    &.center {
      padding: 0;
      .ag-header-cell-label,
      .ag-header-group-cell-label {
        justify-content: center;
      }
    }
  }

  .ag-row-group.ag-full-width-row {
    padding: 0 0 0 @grid-padding;
  }

  .ag-cell {
    padding: 0 0 0 @grid-padding;
    page-break-inside: avoid !important;

    &.ag-cell-highlight,
    &.ag-cell-highlight > * {
      border-color: @blue-6;
      background-color: @blue-6 !important;
      color: #fff;
    }

    &.ag-cell-not-inline-editing.right {
      text-align: right;
      padding: 0 @grid-padding 0 0;
    }
  }

  .ag-cell-span {
    background-color: #fff;
  }

  .cell-wrap-text {
    white-space: normal !important;
  }

  .ag-details-row {
    padding: 0 0 0 40px;
  }
}

.printing .ag-theme-material .ag-ltr {
  font-size: 12px;

  .ag-header-cell {
    padding: 0 0 0 @grid-print-padding;

    &.right {
      .ag-header-cell-label {
        padding: 0 @grid-print-padding 0 0;
      }
    }
  }

  .ag-row-group.ag-full-width-row {
    padding: 0 0 0 @grid-print-padding;
  }

  .ag-cell {
    border-left: solid 1px #e0e0e0;
    padding: 0 0 0 @grid-print-padding;
    &.ag-cell-not-inline-editing.right {
      padding: 0 @grid-print-padding 0 0;
    }
  }
}
